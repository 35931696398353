const config = {
  ENVIRONMENT_NAME: 'dev',
  forwoodId: {
    URL: 'https://id.dev.cvrms.forwoodsafety.com',
    APP_CLIENT_ID: '4mehriglu3bibg0i7q7bavoik0',
    USER_TOKEN_URL: 'https://gpwn72j2wl.execute-api.us-west-2.amazonaws.com/prod'
  },
  apiGateway: {
    REGION: 'us-west-2',
    URL: 'https://sdt1dm0zrd.execute-api.us-west-2.amazonaws.com/prod'
  },
  reactApp: {
    VERSION: 'admin-container-1.9.1',
    HOSTNAME: 'https://admin.dev.cvrms.forwoodsafety.com',
    COMPANY_MICROFRONTEND_URL: 'https://company.dev.cvrms.forwoodsafety.com',
    PHYSICAL_LOCATION_MICROFRONTEND_URL: 'https://locations.dev.cvrms.forwoodsafety.com'
  },
  configurationService: {
    URL: 'https://config.dev.cvrms.forwoodsafety.com',
    WEBSOCKET: 'wss://cjie91dgxh.execute-api.us-west-2.amazonaws.com/dev'
  },
  get forwoodIdAuthUrl() {
    return `${this.forwoodId.URL}/authorize?response_type=token&scope=openid&client_id=${this.forwoodId.APP_CLIENT_ID}&redirect_uri=${this.reactApp.HOSTNAME}&state=`;
  }
};

export default config;